<template>
    <div class="main">
        <div class="row space-between ">
            <div class="title">
                <h4>{{ teamStore.getTeamName }}</h4>
            </div>
            <div>
                <div v-for="(item, index) in state.story.tags" :key="index" class="tags">
                    <span>{{ item }}</span>
                </div>
            </div>
            <div>
                <router-link :to="{ name: 'Home' }">
                    <button>Back</button>
                </router-link>
                <router-link :to="{ name: 'Edit' }">
                    <button>Edit</button>
                </router-link>
            </div>
        </div>
        <div class="detail">
            <div>
                <h3> {{ state.story.heading }} </h3>
            </div>
            <div class="text-editor" v-html="sanitizeHtml(state.story.body)" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { TeamStory } from "@/services/entities/teamStory";
import { useTeamStoryStore } from "@/stores/teamStory";
import { onBeforeMount, onMounted, reactive } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import DOMPurify from "dompurify";
import { useTeamStore } from "@/stores/team";

const teamStoryStore = useTeamStoryStore();
const teamStore = useTeamStore();

const props = defineProps<{
    id: string;
    template: string;
}>();

const state = reactive({
    saved: false,
    story: { heading: "", body: "" } as any as TeamStory,
});

function sanitizeHtml(html: string) {
    return DOMPurify.sanitize(html, { ADD_ATTR: ["target"] });
}

onMounted(async () => {
    if (props.id) {
        if (teamStoryStore.getTeamStories.length === 0) {
            await teamStoryStore.loadTeamStory(props.template);
        }
        const result = teamStoryStore.getTeamStories.find((story) => story.id?.toLowerCase() === props.id?.toLowerCase());
        if (result) {
            state.story = result;
        }
    }
});

</script>

<style lang="scss" scoped>
.tags {
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    background-color: #f0f0f0;
}
.main {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    height: 80%;
}

.text-editor {
    margin-top: 5px;
    width: 100%;
    height: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;

    &.space-between {
        justify-content: space-between;
    }
}

.detail {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 3px;
    background-color: #fff;
}

.row-right {
    display: flex;
    margin-top: 10px;
    align-items: right;
    justify-content: right;
}

a {
    padding-left: 5px;
}
</style>